<template>
  <b-container class="app flex-row align-items-center pace-done container-404-custom">
    <b-row class="justify-content-center">
      <b-col md="5">

        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">404</font>
            </font>
          </h1>
          <h4 class="pt-3">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">¡Uy! </font>
              <font style="vertical-align: inherit;">Estas perdido.</font>
            </font>
          </h4>
          <p class="text-muted">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">No se encontró la página que estás buscando.</font>
            </font>
          </p>

          <b-button variant="outline-dark" class="w-100" @click="volver()">Volver al Inicio</b-button>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {
  methods: {
    volver() {      
      this.$router.push({ name: 'Init' })
    }
  }
}
</script>

<style>
  .container-404-custom {
    display: grid;
  }
</style>
